.navbar-row {
  font-size: 12px;
}
.percent-pie {
  color: $bac--blueHovering;
  font-weight: 600;
  line-height: 32px;
  font-size: 20px;
}
.pie-label {
  color: $bac--blueHovering;
  font-size: 13px;
  font-weight: 400;
}
.modal-content {
  border-radius: 0;
}
.recharts-sector:focus {
  outline: none;
}

/*
.page-row-2 {
  & > [class*="col-"]:nth-child(1) {
    background-color: rgba(227, 244, 252, 1);
    background-clip: padding-box;
    border: 16px solid transparent;
  }
}
*/
.page-row-1, .page-row-2 {
  flex-wrap: wrap;
  
  @media (max-width: $md) {
    align-content: flex-start;
  }
  @media (min-width: $md) {
    height: 491px;
  }
  & > [class*="col-"] {
    @media (min-width: $md) {
      height: 100%;
      overflow: visible;
    }
    background-color: rgba(227, 244, 252, 1);
    background-clip: padding-box;
    border: 16px solid transparent;
    @media (max-width: $sm) {
      height: fit-content;
    }
  }
}
.responsive-container {
  margin: auto;
}
.recharts-surface {
  overflow: visible;
}