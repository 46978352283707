
.row {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
    & > * {
        padding-right: 0;
        padding-left: 0;
        flex-grow: 0;
        flex-shrink: 0;
    }
}
.container {
    max-width: 100%;
}